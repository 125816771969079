import React, { useEffect, useState, useMemo, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, CircularProgress, Box } from "@mui/material";
import {
  getLoginUrl,
  getLogoutUrl,
  parseTokensFromUrl,
  decodeToken,
  isUserInGroup,
} from "./auth";

// Lazy load pages
const WelcomePage = lazy(() => import("./pages/Welcome"));
const ProfilePage = lazy(() => import("./pages/Profile"));
const HomePage = lazy(() => import("./pages/Home"));
const Test = lazy(() => import("./pages/Test"));
const Admin = lazy(() => import("./pages/Admin"));
const UserManagement = lazy(() => import("./pages/Admin/UserManagement"));
const AccessControl = lazy(() => import("./pages/Admin/AccessControl"));
const Videos = lazy(() => import("./pages/Videos"));

function App() {
  const [userAttributes, setUserAttributes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [themeMode, setThemeMode] = useState(() => {
    return localStorage.getItem("themeMode") || "dark";
  });

  useEffect(() => {
    const initializeApp = () => {
      const { idToken, error } = parseTokensFromUrl();
      if (idToken) {
        const decodedToken = decodeToken(idToken);
        setUserAttributes(decodedToken);
        localStorage.setItem("idToken", idToken);
        window.location.hash = "";
      } else if (error) {
        console.error("Authentication error:", error);
        handleSignOut(); // Log out on error
      } else {
        const storedToken = localStorage.getItem("idToken");
        if (storedToken) {
          const decodedToken = decodeToken(storedToken);
          setUserAttributes(decodedToken);
        }
      }
      setLoading(false);
    };

    initializeApp();
  }, []);

  const handleSignIn = () => {
    window.location.href = getLoginUrl();
  };

  const handleSignOut = () => {
    localStorage.removeItem("idToken");
    setUserAttributes(null);
    window.location.href = getLogoutUrl();
  };

  const toggleTheme = () => {
    const newTheme = themeMode === "light" ? "dark" : "light";
    setThemeMode(newTheme);
    localStorage.setItem("themeMode", newTheme);
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
        },
      }),
    [themeMode]
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const isAdmin = userAttributes
    ? isUserInGroup(localStorage.getItem("idToken"), "Administrators")
    : false;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavBar
          userAttributes={userAttributes}
          onSignOut={handleSignOut}
          toggleTheme={toggleTheme}
          themeMode={themeMode}
          isAdmin={isAdmin}
        />
        <Suspense
          fallback={
            <Box>
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            {userAttributes ? (
              <>
                <Route
                  path="/"
                  element={<HomePage userAttributes={userAttributes} />}
                />
                <Route
                  path="/profile"
                  element={<ProfilePage userAttributes={userAttributes} />}
                />
                <Route
                  path="/test"
                  element={<Test userAttributes={userAttributes} />}
                />
                <Route
                  path="/videos"
                  element={<Videos userAttributes={userAttributes} />}
                />
                {isAdmin && (
                  <>
                    <Route
                      path="/admin"
                      element={<Admin userAttributes={userAttributes} />}
                    />
                    <Route
                      path="/admin/user-management"
                      element={
                        <UserManagement userAttributes={userAttributes} />
                      }
                    />
                    <Route
                      path="/admin/access-control"
                      element={
                        <AccessControl userAttributes={userAttributes} />
                      }
                    />
                  </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={<WelcomePage onSignIn={handleSignIn} />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
