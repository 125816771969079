// src/config.js
console.log("Redirect URI:", process.env.REACT_APP_REDIRECT_URI);
const config = {
  cognito: {
    domain: "auth.asphaug.dev", // e.g., 'myapp-auth.auth.eu-west-1.amazoncognito.com'
    clientId: "2afll975nlu1le59bs5vm6hmh5", // e.g., '1h2g3j4k5l6m7n8o9p0q'
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ["email", "openid", "profile"],
    identityPoolId: "eu-north-1:c373abe4-0d52-4e89-9ed6-44da73bb3b2f", // Your Identity Pool ID
    region: "eu-north-1", // Your AWS region
    userPoolId: "eu-north-1_lnOu63URK", // Your User Pool ID
  },
};

export default config;
