// src/auth.js

import { jwtDecode } from "jwt-decode";
import config from "./config";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

const {
  domain,
  clientId,
  redirectUri,
  scopes,
  identityPoolId,
  region,
  userPoolId,
} = config.cognito;

// Function to construct URLs
const buildUrl = (path, params) => {
  const url = new URL(`https://${domain}${path}`);
  url.search = new URLSearchParams(params).toString();
  return url.toString();
};

export const storeTokens = ({ idToken, accessToken }) => {
  localStorage.setItem("idToken", idToken);
  localStorage.setItem("accessToken", accessToken);
};

export const clearTokens = () => {
  localStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
};

export const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return decoded.exp < currentTime;
};

export const isUserInGroup = (token, groupName) => {
  const decoded = jwtDecode(token);
  const groups = decoded["cognito:groups"] || [];
  return groups.includes(groupName);
};

// Login URL
export const getLoginUrl = () => {
  return buildUrl("/login", {
    client_id: clientId,
    response_type: "token",
    scope: scopes.join(" "),
    redirect_uri: redirectUri,
  });
};

// Logout URL
export const getLogoutUrl = () => {
  return buildUrl("/logout", {
    client_id: clientId,
    logout_uri: redirectUri,
  });
};

// Parse tokens from URL hash
export const parseTokensFromUrl = () => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const idToken = params.get("id_token");
  const accessToken = params.get("access_token");
  const error = params.get("error");
  return { idToken, accessToken, error };
};

// Function to decode JWT token
export const decodeToken = (token) => {
  const decodedToken = jwtDecode(token);
  // Check if the groups are present in the decoded token
  const groups = decodedToken["cognito:groups"] || [];
  console.log(token);
  return {
    ...decodedToken,
    groups,
  };
};

// Function to get AWS credentials
export const getAWSCredentials = async (idToken) => {
  const cognitoIdentityClient = new CognitoIdentityClient({ region });

  const credentials = fromCognitoIdentityPool({
    client: cognitoIdentityClient,
    identityPoolId,
    logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
    },
  });

  return credentials;
};

export const getIdToken = () => localStorage.getItem("idToken");
export const getAccessToken = () => localStorage.getItem("accessToken");
